
export const EmployerMainMenu = [
    {
        title:'Dashboard',
        to:'/employer/'
    },
    {
        title:'Activity',
        to:'/activity'
    },
    {
        title:'Messaging',
        to:'/messaging'
    },
    {
        title:'Reports',
        to:'/reports'
    },
    {
        title:'Users',
        to:'/users'
    },
];

export const EmployeeMainMenu = [
    {
        title:'Dashboard',
        to:'/employee/'
    }
];

export const EmployerSecondaryMenu = [
    {
        title:'Eligibility File',
        to:'/employees',
        icon:'account-supervisor',
        classN:'employees'
    },
    {
        title:'Elections File',
        to:'/elections-file',
        icon:'swap-horizontal',
        classN:'elections'
    },
    {
        title:'Payroll File',
        to:'/payroll',
        icon:'cash-usd-outline',
        classN:'payroll'
    },
    {
        title:'Invoice',
        to:'/invoice',
        icon:'credit-card-outline',
        classN:'invoice'
    },
];

export const EmployerSecondaryMenuDirectPay = [
    {
        title:'Eligibility File',
        to:'/employees',
        icon:'account-supervisor',
        classN:'employees'
    },
    {
        title:'Payments',
        to:'/payments',
        icon:'cash-usd-outline',
        classN:'payroll'
    },
    {
        title:'Invoice',
        to:'/invoice',
        icon:'credit-card-outline',
        classN:'invoice'
    },
];

export const EmployeeSecondaryMenuNoEvoshare = [
    {
        title:'Activity',
        to:'/activity',
        icon:'signal-variant',
        classN:'employees'
    },
    {
        title:'Contribution Settings',
        to:'/contributions',
        icon:'cash-usd-outline',
        classN:'payroll'
    },
    {
        title:'Register Account',
        to:'/accounts',
        icon:'account-circle',
        classN:'invoice'
    }
];

export const EmployeePopupSubMenu = [
    {
        title:'Settings',
        action:'settings'
    },
    {
        title:'Contribution Settings',
        action:'contributions',
    },
    {
        title:'Accounts',
        action:'accounts',
    },
    {
        title:'Payments',
        action:'payments',
    },
];
