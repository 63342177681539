import React, {useEffect, useState} from 'react';
import {Switch, Route, useHistory, useLocation} from "react-router";
import EmployerDashboardHeader from "../../components/employerDashboardHeader";
import SubNav from "../../components/nav/subNav";
import {
    EmployerSecondaryMenu,
    EmployerSecondaryMenuDirectPay
} from "../../_menus";
import Employees from "./dashboard/employees";
import Payroll from "./dashboard/payroll";
import Elections from "./dashboard/elections";
import Invoices from "./dashboard/invoice";
import {ADMIN_TYPE, ADMIN_USER_TYPES, Colors, EMPLOYER_CONTACT_TYPE, HR_TYPE} from "../../_constants";
import {useStoreState} from "easy-peasy";

export default function EmployerDashboard(){

    const location = useLocation();
    const history = useHistory();

    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const [secondaryMenu,setSecondaryMenu] = useState(EmployerSecondaryMenu);
    const userType = useStoreState(state=>state.user.type);
    useEffect(()=>{
        if(!ADMIN_USER_TYPES.includes(userType)){
            history.push('/')
        }
    },[userType]);
    useEffect(()=>{
        if(location.pathname === '/employer/'){
            history.push('/employer/employees');
        }
    },[location]);

    useEffect(()=>{
        if(!activeOrganization){
            return;
        }
        if(activeOrganization.plan_token === 'employer-direct'){
            setSecondaryMenu(EmployerSecondaryMenuDirectPay)
        }else{
            setSecondaryMenu(EmployerSecondaryMenu);
        }
    },[activeOrganization]);

    return (
       <>
           <Switch>
                   <Route path={'/employer/employees'}>
                       <EmployerDashboardHeader header={headers.employees} />
                   </Route>
                   <Route path={'/employer/payroll'}>
                       <EmployerDashboardHeader header={headers.payroll} />
                   </Route>
                   <Route path={'/employer/payments'}>
                       <EmployerDashboardHeader header={headers.payments} />
                   </Route>
                   <Route path={'/employer/elections-file'}>
                       <EmployerDashboardHeader header={headers.elections} />
                   </Route>
                   <Route path={'/employer/invoice'}>
                       <EmployerDashboardHeader header={headers.invoice} />
                   </Route>

           </Switch>

           <div id={'dashboard-details'} className={'max-width-container inner-section'}>
               {secondaryMenu.length > 0 &&
                <SubNav menu={secondaryMenu} baseUrl={'/employer'}/>
               }
               <div className={'dashboard-content'}>
                       <Switch>
                           <Route path={'/employer/employees'}>
                                <Employees accentColor={headers.employees.color}/>
                           </Route>
                           <Route path={'/employer/payroll'}>
                               <Payroll accentColor={headers.payroll.color} />
                           </Route>
                           <Route path={'/employer/payments'}>
                               <Payroll accentColor={headers.payroll.color} />
                           </Route>
                           <Route path={'/employer/elections-file'}>
                               <Elections accentColor={headers.elections.color} />
                           </Route>
                           <Route path={'/employer/invoice'}>
                               <Invoices accentColor={headers.invoice.color} />
                           </Route>
                       </Switch>
               </div>
           </div>
       </>

    )
}

const headers = {
    employees:{
        color:Colors.blue,
        title:'Upload a new eligibility file each month',
        subTitle:'Add, modify, delete, and edit employees below',
        fileName:'Eligibility File',
        type:'upload',
        uploadType:'employee'
    },
    payroll:{
        color:Colors.teal,
        title:'Upload your Payroll file after your payroll cycle is complete to ensure on-time payment to your Employees’ accounts',
        subTitle:'View previous payroll files below',
        fileName:'Payroll File',
        type:'upload',
        uploadType:'payroll'
    },
    payments:{
        color:Colors.teal,
        title:'Download detailed transaction histories.',
        subTitle:'',
        fileName:'Payments File',
        type:'download',
        downloadType:'payments'
    },
    elections:{
        color:Colors.orange,
        title:'Review a Pending Election file anytime during the month.  Download a Final Election File anytime after the 25th',
        subTitle:'View Finalized Election Files Below',
        fileName:'Elections File',
        type:'download',
        downloadType:'elections'
    },
    invoice:{
        color:Colors.listBlue,
        title:'Download copies of your previous invoices',
        subTitle:'View Invoice history below',
        fileName:'Invoice PDF',
        type:'download',
        icon:'pdf',
        downloadType:'invoice'
    }
};
