import React from 'react';
import AdminSignupSteps from "./adminSignupSteps";
import {
	ADMIN_TYPE,
	ADMIN_USER_TYPES,
	EMPLOYEE_TYPE,
	EMPLOYEE_TYPE_NO_EVOSHARE,
	EMPLOYER_CONTACT_TYPE,
	HR_TYPE
} from "../../_constants";
import OwnerSignupSteps from "./ownerSignupSteps";
import SignupStepsNoEvoshare from "./employee/signupStepsNoEvoshare";

export default function SignupHeader({user,currentStep,type,organization, includeBillingSteps}){

	return (
		<header className={'full-width simple signup-header'}>
			<div className={'max-width-container header-holder'}>
				<img className={'header-logo'} src={'/images/logo.svg'} />
				{!!user &&
					<div className={'welcome-holder'}>
						{(organization && organization.organization_logo_url) &&
							<img src={organization.organization_logo_url} alt={''}/>
						}
						<span>Welcome <strong>{user.user_display_name}</strong></span>
					</div>
				}
			</div>
			{(type && (type===EMPLOYEE_TYPE_NO_EVOSHARE || type===EMPLOYEE_TYPE)) &&
				<SignupStepsNoEvoshare curStep={currentStep}/>
			}
			{(type && (ADMIN_USER_TYPES.includes(type)) && !includeBillingSteps) &&
				<AdminSignupSteps curStep={currentStep} />
			}
			{(type && (ADMIN_USER_TYPES.includes(type)) && includeBillingSteps) &&
				<OwnerSignupSteps curStep={currentStep} />
			}
		</header>
	)
}

