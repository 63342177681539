import React from 'react'
import { useLocation } from "react-router";
import NoResults from "../noResults";
import { baseApiUrl, organizationInvoicesUrl } from "../../_constants";
import { useStoreState } from "easy-peasy";
import axios from "axios";

export default function InvoiceList({ files, accentColor }) {
    const location = useLocation();
    const [activeInvoice, setActiveInvoice] = React.useState(null);
    function downloadFile(path) {
        axios.get('/auth/generate-token').then(response => {
            if (response.data.token) {
                window.open(path + response.data.token, '_blank');
            }
        }).catch(e => { })
    }

    function downloadDebitFile(debit, type = null) {
        axios.get('/auth/generate-token').then(response => {
            let path = baseApiUrl + '/organizations/' + debit.organization_id + '/debits/';
            type = type ? '&type=' + type : '';
            if (response.data.token) {
                if (debit.organization_debit_processor.toLowerCase() === 'hold') {
                    path += 'download-invoice-day-debits/?processor=hold&auth-token=' + response.data.token + '&invoice_id=' + debit.invoice_id + type;
                    window.open(path, '_blank');
                } else {
                    path = debit.download_url + response.data.token + type;
                    window.open(path, '_blank');
                }
            }
        }).catch(e => { })
    }

    function toggleInvoice(invoiceId) {
        if (activeInvoice === invoiceId) {
            setActiveInvoice(null)
            return;
        }
        setActiveInvoice(invoiceId);
    }

    return (
        <div className={'invoices-list dashboard-list'}>
            {location.state.layout !== 'mobileMax' &&
                <div className={'table-row file-row header'}>
                    <span>File name</span>
                    <span>Processed</span>
                    <span>status</span>
                    <span>updated</span>
                    <span>Download</span>
                </div>
            }
            {location.state.layout !== 'mobileMax' && files.map((file) => {
                return (
                    <>
                        <div key={file.file_name + file.invoice_timestamp} style={{ paddingTop: 10, paddingBottom: 10 }} className={'table-row  file-row ' + (file.invoice_status_status === 'error' ? 'flagged' : '')}>
                            <span >{file.invoice_status_status === 'error' && <img src={'/images/new-alert.svg'} alt={'new alert'} />}{file.file_name}</span>
                            <span >{new Date(file.invoice_timestamp.replace(' ', 'T')).toLocaleDateString()}</span>
                            <span >{file.invoice_status_status}</span>
                            <span >{new Date(file.invoice_status_timestamp.replace(' ', 'T')).toLocaleDateString()}</span>
                            <span><a className={'invoice'}
                                onClick={e => { e.preventDefault(); downloadFile(baseApiUrl + organizationInvoicesUrl(file.organization_id) + '/' + file.invoice_id + '?auth-token=') }}
                                href={'#'}
                            ><span style={{ color: accentColor }} className={'mdi mdi-file'} />
                            </a> </span>
                        </div>
                        {file.debits?.length > 0 &&
                            <div key={file.invoice_id} style={{ paddingTop: 6, paddingBottom: 6, borderBottom: '1px solid rgba(0,0,0,.2)', borderRadius: 0, backgroundColor: '#f4f7fd5e' }} className={'table-row  file-row invoice-debit-row tablet ' + (file.flagged ? 'flagged' : '')} onClick={(e) => {
                                if (e.target.tagName === 'A') return;
                                toggleInvoice(file.invoice_id)
                            }}>
                                <div>
                                    <span className='title accordian-title'>Invoice Debits</span>
                                </div>
                                {(activeInvoice === file.invoice_id) &&
                                    <div className='debit-accordian'>
                                        {file.debits?.map((debit) => (
                                            <div key={Math.random()} >
                                                <div>
                                                    <span className={'title'}>Debit Date</span>
                                                    <span >{debit.organization_debit_timestamp ? new Date(debit.organization_debit_timestamp.replace(' ', 'T')).toLocaleDateString() : 'Unresolved'}</span>
                                                </div>
                                                <div>
                                                    <span className={'title'}>Amount</span>
                                                    <span >${debit.debit_total}</span>
                                                </div>
                                                <div>
                                                    <span className={'title'}>Processor</span>
                                                    <span >{debit.organization_debit_processor}</span>
                                                </div>
                                                <div>
                                                    <span style={{ display: "flex" }}><a className={'invoice'}
                                                        onClick={e => { e.preventDefault(); e.stopPropagation(); downloadDebitFile(debit) }}
                                                        href={'#'}
                                                    ><span style={{ color: accentColor }} className={'mdi mdi-file'} />
                                                    </a>
                                                        <a style={{ marginLeft: 5 }}
                                                            className={'invoice'}
                                                            onClick={e => { e.preventDefault(); e.stopPropagation(); downloadDebitFile(debit, 'pdf') }}
                                                            href={'#'}
                                                        >pdf
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>
                        }

                    </>
                )
            })}
            {(location.state.layout === 'mobileMax') && files.map((file) => {
                return (
                    <div key={file.file_name + file.invoice_timestamp} className={'table-row  file-row tablet ' + (file.flagged ? 'flagged' : '')}>
                        <div>
                            <span className={'title'}>File name</span>
                            <span >{file.invoice_status_status === 'error' && <img src={'/images/new-alert.svg'} alt={'new alert'} />}{file.file_name}</span>
                        </div>
                        <div>
                            <span className={'title'}>Processed</span>
                            <span >{new Date(file.invoice_timestamp.replace(' ', 'T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <span className={'title'}>Status</span>
                            <span >{file.invoice_status_status}</span>
                        </div>
                        <div>
                            <span className={'title'}>Updated</span>
                            <span >{new Date(file.invoice_status_timestamp.replace(' ', 'T')).toLocaleDateString()}</span>
                        </div>
                        <div>
                            <span><a className={'invoice'}
                                onClick={e => { e.preventDefault(); downloadFile(baseApiUrl + organizationInvoicesUrl(file.organization_id) + '/' + file.invoice_id + '?auth-token=') }}
                                href={'#'}
                            ><span style={{ color: accentColor }} className={'mdi mdi-file'} />
                            </a> </span>
                        </div>
                    </div>
                )
            })}
            {files.length <= 0 && <NoResults />}
        </div>
    )

}


