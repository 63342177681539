import React, {useEffect, useState} from 'react';
import {Switch, Route, useHistory, useLocation} from "react-router";
import SubNav from "../../components/nav/subNav";
import {EmployeeSecondaryMenuNoEvoshare} from "../../_menus";
import {Colors, EMPLOYEE_TYPE} from "../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import EmployeeDashboardHeader from "../../components/employeeDashboardHeader";
import EmployeeActivity from "../employee/dashboard/employeeActivity";
import ContributionSettings from "../employee/dashboard/contributionSettings";
import EmployeeAccounts from "./dashboard/emplyeeAccounts";
import HasUnverifiedAccountsPopup from "../../components/popups/hasUnverifiedAccountsPopup";
import HasRightfootAccountsPopup from '../../components/popups/hasRightfootAccountsPopup';
import HasInvalidSpinwheelPopup from "../../components/popups/hasInvalidSpinwheelPopup";
import SpinwheelLoansPopup from '../../components/popups/spinwheelLoansPopup';
import { SPINWHEEL_MODULES } from '../../hooks/useSpinwheelDim';
import AlertsPopup from '../../components/popups/alertsPopup';

export default function EmployeeDashboard(){
	const location = useLocation();
	const history = useHistory();
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const hasUnverifiedAccounts = useStoreState(state=>state.user.hasUnverifiedAccounts);
	const hasRightfootAccounts = useStoreState(state=>state.user.hasRightfootAccounts);
	const hasAcknowledgedUnverifiedAccounts = useStoreState(state=>state.user.acknowledgedUnverifiedAccounts)
	const setHasAcknowledgedUnverifiedAccounts = useStoreActions(actions=>actions.user.setAcknowledgedUnverifiedAccounts)
	const hasAcknowledgedRightfootAccounts = useStoreState(state=>state.user.acknowledgedRightfootAccounts)
	const setHasAcknowledgedRightfootAccounts = useStoreActions(actions=>actions.user.setAcknowledgedRightfootAccounts)

	const showSpinwheelInvalid = useStoreState(state=>state.user.hasInvalidSpinwheelAccounts);
	const acknowledgedInvalidSpinwheelAccounts = useStoreState(state=>state.spinwheel.acknowledgedInvalidSpinwheelAccounts)
	const setAcknowledgedInvalidSpinwheelAccounts = useStoreActions(actions=>actions.spinwheel.setAcknowledgedInvalidSpinwheelAccounts)
	const setSawSpinwheelInvalidPopup = useStoreActions(actions=>actions.user.setSawSpinwheelInvalidPopup)

	const showLoanModal = useStoreState(state=>state.spinwheel.showLoanModal);
	const setShowLoanModal = useStoreActions(actions=>actions.spinwheel.setShowLoanModal);
	const authToken = useStoreState(state=>state.user.token);

	const showAlertsModal = useStoreState(state=>state.userAlerts.showModal);
	
	const [secondaryMenu,setSecondaryMenu] = useState([]);
	const userType = useStoreState(state=>state.user.type);

	const updateMenu = () =>{
		if(!activeOrganization){
			return;
		}
		setSecondaryMenu(EmployeeSecondaryMenuNoEvoshare);
	}

	useEffect(()=>{
		if(userType !== EMPLOYEE_TYPE){
			history.push('/')
		}
	},[userType]);
	useEffect(()=>{
		if(location.pathname === '/employee/'){
			history.push('/employee/activity');
		}
	},[location]);
	useEffect(()=>{
		updateMenu();
	},[activeOrganization]);

	useEffect(()=>{
		updateMenu();
	},[])

	return (
		<>
			{(!hasAcknowledgedUnverifiedAccounts && hasUnverifiedAccounts) &&
				<HasUnverifiedAccountsPopup closeHandler={()=>{setHasAcknowledgedUnverifiedAccounts(true)}} />
			}
			{(!hasAcknowledgedRightfootAccounts && hasRightfootAccounts) &&
				<HasRightfootAccountsPopup closeHandler={()=>{setHasAcknowledgedRightfootAccounts(true)}} />
			}
			{(!acknowledgedInvalidSpinwheelAccounts && showSpinwheelInvalid) &&
				<HasInvalidSpinwheelPopup closeHandler={()=>{setAcknowledgedInvalidSpinwheelAccounts(true); setSawSpinwheelInvalidPopup(true)}} organizationId={activeOrganization.organization_id} />
			}
			{showLoanModal &&
				<SpinwheelLoansPopup closeHandler={()=>{setShowLoanModal(false)}} authToken={authToken} connectionType={SPINWHEEL_MODULES.LOAN_SERVICERS_LOGIN} />
			}
			{showAlertsModal && <AlertsPopup />}

			<Switch>
				<Route path={'/employee/activity'}>
					<EmployeeDashboardHeader header={headers.activity} />
				</Route>
				<Route path={'/employee/contributions'}>
					<EmployeeDashboardHeader header={headers.contributions} />
				</Route>
				<Route path={'/employee/accounts'}>
					<EmployeeDashboardHeader header={headers.accounts} />
				</Route>
			</Switch>

			<div id={'dashboard-details'} className={'max-width-container inner-section'}>
				{secondaryMenu.length > 0 &&
					<SubNav menu={secondaryMenu} baseUrl={'/employee'}/>
				}
				<div className={'dashboard-content'}>
					<Switch>
						<Route path={'/employee/activity'}>
							<EmployeeActivity accentColor={headers.activity.color}/>
						</Route>
						<Route path={'/employee/contributions'}>
							<ContributionSettings accentColor={headers.contributions.color}/>
						</Route>
						<Route path={'/employee/accounts'}>
							<EmployeeAccounts accentColor={headers.accounts.color}/>
						</Route>
					</Switch>
				</div>
			</div>
		</>

	)
}

const headers = {
	activity:{
		color:Colors.blue,
		subTitle:'Access Your Account Activity Details Below'
	},
	contributions:{
		color:Colors.teal,
		subTitle:'Contributions last updated on %last_date'
	},
	accounts:{
		color:Colors.listBlue,
		subTitle:'Account last updated on %last_date'
	},
};
