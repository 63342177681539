import React, {useEffect, useState} from 'react'
import {CSSTransition} from "react-transition-group";
import FileListPlaceholder from "../../../components/placeholders/fileListPlaceholder";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import InvoiceList from "../../../components/lists/invoiceList";

export default function Invoices({accentColor}){
    const [loading,setLoading] = useState(true);

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);

    const [files,setFiles] = useState([]);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setNewestFile = useStoreActions(actions=>actions.newestFileForDownload.setFile);

    const scrollLoad = (page) =>{
        setIsScrollLoading(true);
        let queryString = '?page='+page;
        setCurrentPage(page);
        axios.get('/organizations/'+activeOrganization['organization_id']+'/debits/get-debits-by-invoices' + queryString).then(response=>{
            setFiles([...files,...response.data.invoices]);
            setLoading(false);
            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    };

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get('/organizations/'+activeOrganization['organization_id']+'/debits/get-debits-by-invoices').then(response=>{
            setFiles(response.data.invoices);
            if(response.data.invoices.length>0) {
                let newestFile = response.data.invoices[0];
                setNewestFile({type: 'invoice', id: newestFile.invoice_id,date:newestFile.invoice_timestamp});
            }
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[]);

    return (
        <>
            <div className={'content-header'}>
                <div className={'title'}>
                    <h1 style={{color:accentColor}} >
                        Invoice files
                    </h1>
                    <a className={'sub-header'}  href={'#'}>
                        Download files
                    </a>
                </div>
            </div>

            <CSSTransition
                in={loading}
                timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <FileListPlaceholder />
            </CSSTransition>
            <CSSTransition
                in={!loading}
                timeout={{
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <InfiniteScroll
                    pageStart={1}
                    loadMore={isScrollLoading?null:scrollLoad}
                    initialLoad={false}
                    hasMore={(currentPage<totalPages)}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >
                    <InvoiceList accentColor={accentColor} files={files} linkClass={'invoice'} />
                </InfiniteScroll>
            </CSSTransition>
        </>
    )
}
