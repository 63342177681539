import React from 'react'
import { useLocation } from "react-router";
import NoResults from "../noResults";
import axios from "axios";
import { baseApiUrl } from '../../_constants';

export default function InvoiceDebitList({ invoices = [], accentColor }) {
    const location = useLocation();
    const [activeInvoice, setActiveInvoice] = React.useState(null);
    function downloadFile(debit, type = null) {
        axios.get('/auth/generate-token').then(response => {
            let path = baseApiUrl + '/organizations/' + debit.organization_id + '/debits/';
            type = type ? '&type=' + type : '';
            if (response.data.token) {
                if (debit.organization_debit_processor.toLowerCase() === 'hold') {
                    path += 'download-invoice-day-debits/?processor=hold&auth-token=' + response.data.token + '&invoice_id=' + debit.invoice_id + type;
                    window.open(path, '_blank');
                } else {
                    path = debit.download_url + response.data.token + type;
                    window.open(path, '_blank');
                }
            }
        }).catch(e => { })
    }

    function toggleInvoice(invoiceId) {
        if (activeInvoice === invoiceId) {
            setActiveInvoice(null)
            return;
        }
        setActiveInvoice(invoiceId);
    }

    return (
        <div className={'invoices-debit-list dashboard-list'}>

            {invoices && invoices.map((invoice) => {
                return !invoice.debits ? null : (
                    <div key={invoice.invoice_id} className={'table-row  file-row invoice-debit-row tablet ' + (invoice.flagged ? 'flagged' : '')} onClick={(e) => {
                        if (e.target.tagName === 'A') return;
                        toggleInvoice(invoice.invoice_id)
                    }}>
                        <div>
                            <span className={'title'}>Invoice Date</span>
                            <span >{new Date(invoice.invoice_timestamp.replace(' ', 'T')).toLocaleDateString()}</span>
                        </div>
                        {(activeInvoice === invoice.invoice_id) &&
                            <div className='debit-accordian'>
                                {invoice.debits?.map((debit) => (
                                    <div key={Math.random()} >
                                        <div>
                                            <span className={'title'}>Debit Date</span>
                                            <span >{debit.organization_debit_timestamp ? new Date(debit.organization_debit_timestamp.replace(' ', 'T')).toLocaleDateString() : 'Unresolved'}</span>
                                        </div>
                                        <div>
                                            <span className={'title'}>Amount</span>
                                            <span >${debit.debit_total}</span>
                                        </div>
                                        <div>
                                            <span className={'title'}>Processor</span>
                                            <span >{debit.organization_debit_processor}</span>
                                        </div>
                                        <div>
                                            <span style={{ display: "flex" }}><a className={'invoice'}
                                                onClick={e => { e.preventDefault(); e.stopPropagation(); downloadFile(debit) }}
                                                href={'#'}
                                            ><span style={{ color: accentColor }} className={'mdi mdi-file'} />
                                            </a>
                                                <a style={{ marginLeft: 5 }}
                                                    className={'invoice'}
                                                    onClick={e => { e.preventDefault(); e.stopPropagation(); downloadFile(debit, 'pdf') }}
                                                    href={'#'}
                                                >pdf
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                    </div>
                )
            })}
            {invoices.length <= 0 && <NoResults />}
        </div>
    )

}


